import React from 'react'
import Authentication from './Authentication'
import AuthContext from './context'

const AuthenticationProvider = ({ children, ...props }) => {
  return (
    <Authentication {...props}>
      {api => (
        <AuthContext.Provider value={api}>{children}</AuthContext.Provider>
      )}
    </Authentication>
  )
}

export default AuthenticationProvider
