import jwt from 'jsonwebtoken'
import { NONCE, JWKS } from './constants'
import nanoid from 'nanoid'
import axios from 'axios'

import {
  ACCESS_TOKEN,
  ID2_REDIRECT,
  ID_TOKEN,
  PRAXIS_REDIRECT_AFTERAUTH,
} from './constants'

export const isDevEnv = () => {
  return ['local', 'qa', 'dev', 'stg'].includes(process.env.REACT_APP_ENV)
}

/*  
    _______________________________________________
   | URLS                                          |
   |_______________________________________________|
   | Helper methods for URL string manipulation    |
   |_______________________________________________|
*/

export const isTargetDomain = url =>
  /^([^/]+:)?\/{2,3}[^/]+?(\.target\.com|\.tgt)(:|\/|$)/i.test(url)

export function shouldSendHeaders(url) {
  const result = isTargetDomain(url)
  if (!result) warnUnappliedLocalHeaders(url)
  return result
}

function isLocalUrl(url) {
  try {
    const hostname = (
      createURLObject(url) || { hostname: '' }
    ).hostname.toLowerCase()
    return ['127.0.0.1', 'localhost'].includes(hostname)
  } catch (err) {
    return false
  }
}

// sends a console warning in non-production environments when axios headers were not applied for a localhost/127.0.0.1 URL
var localWarningSent = false
function warnUnappliedLocalHeaders(url) {
  if (!isLocalUrl(url)) return // don't warn for non-local URLs
  if (localWarningSent) return // don't warn more than once
  if (!isDevEnv()) return // don't warn in non-prod
  localWarningSent = true
  const requestUrl = createURLObject(url)
  const warningMessage = `Praxis Warning: Bearer token not applied. (click for details)`
  console.groupCollapsed(warningMessage)
  console.warn(`An authorization header was not automatically applied for request(s) to '${requestUrl.origin}' because it is not a '*.target.com' or '*.tgt' domain.

More info:
https://praxis.prod.target.com/guides/authentication#sending-tokens-to-a-local-api`)
  console.groupEnd(warningMessage)
}

function createURLObject(url, params) {
  if (!url) return null
  let newUrl
  try {
    newUrl = new URL(url)
  } catch (e) {
    newUrl = new URL(url, window.location.origin)
  }

  // convert to URL safe if given params
  newUrl.searchParams.forEach((value, key) => {
    newUrl.searchParams.set(key, value)
  })

  // add given params, would overwrite any given by URL
  if (params) {
    for (let [key, value] of Object.entries(params)) {
      newUrl.searchParams.set(key, value)
    }
  }

  return newUrl
}

export function createUrl(url, params) {
  let newUrl = createURLObject(url, params)
  if (newUrl) return newUrl.toString()
  return null
}

export function createLoginUrl(authorizationUrl, params = {}) {
  if (!authorizationUrl) return null

  const urlParams = {
    client_id: params.clientId,
    nonce: getNonce(),
    redirect_uri: params.loginRedirect,
    response_type: params.responseType,
    scope: params.scope,
    token_type: params.tokenType,
  }

  return createUrl(authorizationUrl, urlParams)
}

export function createLogoutUrl(logoutUrl, params = {}) {
  if (!logoutUrl) return null

  let urlParams = {}
  if (params.logoutRedirect) urlParams.target = params.logoutRedirect

  return createUrl(logoutUrl, urlParams)
}

export function getTokensFromHash(w = window) {
  const hashAsParams = String(w.location.hash).replace(/#/, '?')
  const hash = new URLSearchParams(hashAsParams)

  const accessToken = hash.get(ACCESS_TOKEN)
  const identityToken = hash.get(ID_TOKEN)

  return {
    accessToken,
    identityToken,
  }
}

// creates a popup window given a URL and matches the size of the popupOptions prop
export const createPopupWindow = (url, popupOptions) => {
  const width = popupOptions.width || 482
  const height = popupOptions.height || 680
  const top = Math.floor(window.screenY + (window.outerHeight - height) / 2.5)
  const left = Math.floor(window.screenX + (window.outerWidth - width) / 2)
  const features = `width=${width},height=${height},top=${top},left=${left}`
  return window.open(url, '_blank', features)
}

/*  
    _______________________________________________
   | TOKENS                                        |
   |_______________________________________________|
   | Helper methods and logic for token validation |
   |_______________________________________________|
*/

export const removeBearer = tokenString =>
  tokenString ? tokenString.replace('Bearer ', '') : tokenString

export const injectBearer = tokenString =>
  tokenString && tokenString.includes('Bearer')
    ? tokenString
    : `Bearer ${tokenString}`

export function formatGroup(memberOf = []) {
  return memberOf === null
    ? []
    : memberOf.map(group => /(CN=)([a-zA-Z0-9-]+)/.exec(group)[2])
}

export function getWellKnownUrl(authorizationUrl) {
  const wellKnownURL = new URL(authorizationUrl)
  wellKnownURL.pathname = '/.well-known/openid-configuration'
  return wellKnownURL
}

export async function fetchUserInfo(accessToken, authorizationUrl) {
  // Convert authorizationURL from props to well-known URL
  const wellKnownURL = getWellKnownUrl(authorizationUrl)
  try {
    const wellKnown = await axios.get(wellKnownURL)
    const userInfoEndpoint = wellKnown.data['userinfo_endpoint']
    const userInfo = await axios.get(userInfoEndpoint, {
      headers: { Authorization: injectBearer(accessToken) },
    })
    return userInfo.data
  } catch (e) {
    return null
  }
}

export function formatUserInfo(accessToken, idToken, options = {}) {
  const tokenType = options.accessTokenType || ''
  const userInfo = typeof idToken === 'string' ? jwt.decode(idToken) : idToken
  let info = {
    accessToken:
      tokenType === 'Bearer' ? injectBearer(accessToken) : accessToken,
    email: userInfo.mail,
    firstName: userInfo.firstname,
    lastName: userInfo.lastname,
    fullName: `${userInfo.firstname} ${userInfo.lastname}`,
    lanId: userInfo.samaccountname || userInfo.lanid,
    memberOf: formatGroup(userInfo.memberof),
  }

  // Allow extra fields to be added in if specified
  const { addFields = [] } = options
  addFields.forEach(field => {
    info[field] = userInfo[field]
  })

  return info
}

// https://github.com/auth0/node-jwks-rsa/blob/master/src/utils.js#L1-L5
// converts cert to public key
export function certToPEM(cert) {
  cert = cert.match(/.{1,64}/g).join('\n')
  cert = `-----BEGIN CERTIFICATE-----\n${cert}\n-----END CERTIFICATE-----\n`
  return cert
}

// verifies tokens asynchronously and tries to find the matching `kid` in given keys
export function verifyToken(token, config, keys, cb) {
  return jwt.verify(
    token,
    (header, cb) => {
      var key = keys.find(k => k.kid === header.kid)
      key
        ? cb(null, certToPEM(key.x5c[0]))
        : cb(new Error('kid not found.'), null)
    },
    config,
    cb
  )
}

// Checks the validity of access token and identity token
// given an authorization URL and client ID to verify against
export async function tokensAreValid(
  accessToken,
  idToken,
  authorizationUrl,
  clientId,
  nonce
) {
  // Default is no
  let verify = false

  // Convert authorizationURL from props to well-known URL
  const wellKnownURL = getWellKnownUrl(authorizationUrl)
  let algorithms, issuer, keys, jwksUrl
  try {
    const wellKnown = await axios.get(wellKnownURL.toString())
    jwksUrl = wellKnown.data.jwks_uri
    keys = await getJWKS(jwksUrl)
    issuer = wellKnown.data.issuer
    algorithms = wellKnown.data.id_token_signing_alg_values_supported // currently no specific one for access tokens
  } catch (e) {
    return false
  }

  // 1. verify access token and use what we learn to compare with identity token
  let accessTokenDecoded = null
  const accessConfig = {
    algorithms,
    issuer,
  }

  // https://auth0.com/docs/tokens/guides/jwt/verify-jwt-signature-using-jwks#should-i-cache-my-signing-keys-
  // Check signature using cached keys
  // if it fails, invalidate cache and fetch new JWKS
  let attempts = 0
  const cb = (err, decoded) => {
    verify = err ? false : true
    accessTokenDecoded = decoded
  }
  do {
    await verifyToken(removeBearer(accessToken), accessConfig, keys, cb)
    if (!verify) {
      storageClear(JWKS)
      keys = await getJWKS(jwksUrl)
      attempts++
    }
  } while (!verify && attempts <= 1)

  // if access token is bad just stop here
  if (!verify) return false

  // 2. check identity token (optional)
  if (idToken) {
    const idConfig = {
      algorithms,
      audience: clientId,
      issuer,
      nonce,
      subject: accessTokenDecoded.username, // make sure access and identity tokens represent the same entity
    }
    await verifyToken(idToken, idConfig, keys, (err, decoded) => {
      verify = err ? false : true
    })
  }

  return verify
}

export function matchesCookie(cookieName, session) {
  try {
    const cookieVal = JSON.parse(window.atob(cookieGet(cookieName)))
    return cookieVal.login_status && cookieVal.user === session.userInfo.lanId
  } catch (e) {
    return false
  }
}

/*  
    ____________________________________________________
   | STORAGE                                            |
   |____________________________________________________|
   | Helper methods to manage resources in localStorage |
   |____________________________________________________|
*/

export function storageSet(k, v) {
  window.localStorage.setItem(k, v)
}

export function storageGet(k) {
  return window.localStorage.getItem(k)
}

export function storageClear(k) {
  window.localStorage.removeItem(k)
}

export function setKeys(
  accessToken,
  accessTokenKey = ACCESS_TOKEN,
  tokenType = '',
  idToken
) {
  storageSet(
    accessTokenKey,
    tokenType === 'Bearer' ? injectBearer(accessToken) : accessToken
  )
  storageSet(ID_TOKEN, idToken)
}

export function getRedirect() {
  const redirect = storageGet(PRAXIS_REDIRECT_AFTERAUTH)
  if (redirect) {
    storageClear(PRAXIS_REDIRECT_AFTERAUTH)
    storageClear(ID2_REDIRECT)
    return redirect
  }
}

export function getNonce() {
  let nonce = storageGet(NONCE)
  if (!nonce) {
    // no nonce set, so generate a random one
    nonce = nanoid()
    storageSet(NONCE, nonce)
  }
  return nonce
}

export async function getJWKS(jwksUri) {
  let jwks = JSON.parse(storageGet(JWKS))
  if (!jwks) {
    const fetch = await axios.get(jwksUri)
    jwks = fetch.data.keys // all the possible public keys
    storageSet(JWKS, JSON.stringify(jwks))
  }
  return jwks
}

export function clearAllStorage(accessTokenKey = ACCESS_TOKEN) {
  storageClear(accessTokenKey)
  storageClear(ID_TOKEN)
  storageClear(PRAXIS_REDIRECT_AFTERAUTH)
  storageClear(ID2_REDIRECT)
  storageClear(NONCE)
}

/*  
    ____________________________________________________
   | COOKIES                                            |
   |____________________________________________________|
   | Helper methods to manage resources in cookies      |
   |____________________________________________________|
*/

export function cookieGet(key) {
  if (!key) return null
  const cookies = document.cookie
    .replace(/\s/g, '')
    .split(';')
    .map(c => (c.includes('=') ? c.split('=') : []))

  if (!cookies.length) return null

  const map = new Map(cookies)
  const value = map.get(key)
  return value ? value : null
}
