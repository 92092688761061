import React from 'react'
import {
  func,
  string,
  arrayOf,
  object,
  oneOfType,
  instanceOf,
} from 'prop-types'
import { Route } from 'react-router-dom'
import { createUrl } from './util'
import Session from './Session'
import { ACCESS_DENIED } from './constants'

const ProtectedRoute = ({
  path,
  exact,
  strict,
  location,
  sensitive,
  ...props
}) => {
  return (
    <Route
      path={path}
      exact={exact}
      strict={strict}
      location={location}
      sensitive={sensitive}
      render={routeProps => {
        const {
          allowed,
          component: Component,
          onUnauthenticated,
          onUnauthorized,
          unauthenticatedRoute,
          unauthorizedRoute,
          session,
          render: renderProp,
        } = props
        const authenticated =
          session && session instanceof Session && session.isAuthenticated()
        const authorized = authenticated && session.isAuthorized(allowed)
        const redirect = { redirect: window.location.href }

        const func = authenticated
          ? authorized
            ? renderProp
              ? () => renderProp(routeProps)
              : null
            : () =>
                onUnauthorized
                  ? onUnauthorized(redirect)
                  : window.location.replace(createUrl(unauthorizedRoute))
          : () =>
              onUnauthenticated
                ? onUnauthenticated(redirect)
                : window.location.replace(createUrl(unauthenticatedRoute))

        return typeof func === 'function' ? (
          func()
        ) : (
          <Component {...routeProps} />
        )
      }}
    />
  )
}

ProtectedRoute.propTypes = {
  allowed: arrayOf(string).isRequired,
  component: oneOfType([func, object]),
  onUnauthenticated: func,
  onUnauthorized: func,
  session: instanceOf(Session),
  unauthorizedRoute: string,
  unauthenticatedRoute: string,
  render: func,
}

ProtectedRoute.defaultProps = {
  allowed: [],
  unauthorizedRoute: ACCESS_DENIED,
  unauthenticatedRoute: ACCESS_DENIED,
  component: () => null,
}

export default ProtectedRoute
