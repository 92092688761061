import jwt from 'jsonwebtoken'
import { formatUserInfo, tokensAreValid, fetchUserInfo } from './util'

const CLIENT_ID_CONFIG = 'clientId'
const AUTH_URL_CONFIG = 'authorizationUrl'
const NONCE_CONFIG = 'nonce'
const REQUIRED_CONFIGS = [CLIENT_ID_CONFIG, AUTH_URL_CONFIG, NONCE_CONFIG]

class SessionError extends Error {
  constructor() {
    super('Please validate session before trying to access session info')
    Error.captureStackTrace(this, SessionError)
  }
}

class Session {
  #accessToken = ''
  #identityToken = ''
  #config = {}
  #validated = false
  #expires
  #userInfo

  constructor(accessToken, identityToken = '', config = {}) {
    // check for required accessToken
    if (typeof accessToken !== 'string' || !accessToken.length) {
      throw TypeError('Access token must be provided as a string')
    }

    // check for optional identityToken
    if (
      identityToken &&
      (typeof identityToken !== 'string' || !identityToken.length)
    ) {
      throw TypeError('You provided an identity token but it is not a string')
    }

    REQUIRED_CONFIGS.forEach(c => {
      const val = config[c]
      if (!val || !val.length) {
        throw TypeError(`Please provide '${c}' in options paramter`)
      }
    })

    this.#accessToken = accessToken
    this.#identityToken = identityToken
    this.#config = config
  }

  get accessToken() {
    return this.#accessToken
  }

  get identityToken() {
    return this.#identityToken
  }

  get config() {
    return this.#config
  }

  get validated() {
    return this.#validated
  }

  get identity() {
    if (!this.validated) {
      throw new SessionError()
    }

    return jwt.decode(this.identityToken)
  }

  get access() {
    if (!this.validated) {
      throw new SessionError()
    }

    return jwt.decode(this.accessToken)
  }

  get userInfo() {
    if (!this.validated) {
      throw new SessionError()
    }

    return this.#userInfo
  }

  async validate() {
    // if identity token was not provided, fetch a user info using accessToken
    let userInfo
    if (!this.identityToken) {
      userInfo = await fetchUserInfo(
        this.accessToken,
        this.config[AUTH_URL_CONFIG]
      )
      if (!userInfo) {
        this.#validated = false
        this.#expires = 0
        Object.freeze(this)
        return false
      }
    }
    const isValid = await tokensAreValid(
      this.accessToken,
      this.identityToken,
      this.config[AUTH_URL_CONFIG],
      this.config[CLIENT_ID_CONFIG],
      this.config[NONCE_CONFIG]
    )
    if (isValid) {
      this.#validated = isValid
      this.#expires = this.access.exp

      const { accessTokenType, addFields } = this.config
      this.#userInfo = formatUserInfo(
        this.accessToken,
        this.identityToken || userInfo,
        {
          accessTokenType,
          addFields,
        }
      )
    }

    // once validated (or not) freeze object to prevent manipulation
    Object.freeze(this)
    return isValid
  }

  isAuthenticated() {
    if (!this.validated) {
      throw new SessionError()
    }

    return this.validated && !this.hasExpired
  }

  isAuthorized(groups = []) {
    return this.isAuthenticated() && groups.length
      ? [...this.userInfo.memberOf].filter(group => groups.includes(group))
          .length > 0
      : true
  }

  get hasExpired() {
    if (!this.validated) {
      throw new SessionError()
    }

    // Convert date from milliseconds to seconds
    const now = Date.now() / 1000
    // Is expire time in the past?
    return this.#expires < now
  }
}

export default Session
