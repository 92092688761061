import React, { useEffect, useState, useCallback } from 'react'
import T from 'prop-types'
import axios from 'axios'
import {
  clearAllStorage,
  createLoginUrl,
  createLogoutUrl,
  createPopupWindow,
  getNonce,
  getRedirect,
  getTokensFromHash,
  isDevEnv,
  injectBearer,
  removeBearer,
  setKeys,
  shouldSendHeaders,
  storageClear,
  storageGet,
  storageSet,
  matchesCookie,
} from './util'
import { ACCESS_TOKEN, ID_TOKEN, PRAXIS_REDIRECT_AFTERAUTH } from './constants'
import Loading from './Loading'
import Session from './Session'

// auth interceptor to be ejected later
let authInterceptor = null

// Auth component
const Authentication = ({
  accessTokenKey,
  authorizationUrl,
  children,
  clientId,
  doesSSOCookieMatch,
  errorCallback,
  extraUserInfoFields,
  popUp,
  loadingIndicator: LoadingC,
  loginRedirect,
  logoutRedirect,
  logoutUrl,
  nonce,
  onLogin,
  onLogout,
  popUpOptions,
  responseType,
  scope,
  noHeaders,
  shouldSendHeaders,
  hideLoadingIndicator,
  SSOCookieName,
  tokenType,
  ...props
}) => {
  const [session, setSession] = useState(null)

  // Helper for saving session from token information
  // 1. checks validity
  // 2. formats information
  // 3. saves tokens to localStorage (optional)
  // 4. calls onLogin callback and sets state
  // 5. redirect page (optional)
  const saveSession = useCallback(
    async (
      accessToken,
      identityToken,
      setStorage = false,
      redirect = false
    ) => {
      const session = new Session(accessToken, identityToken, {
        accessTokenType: tokenType,
        addFields: extraUserInfoFields,
        authorizationUrl,
        clientId,
        nonce: getNonce(),
      })
      let valid = await session.validate()
      if (SSOCookieName) {
        valid = doesSSOCookieMatch(SSOCookieName, session)
      }
      if (valid) {
        if (setStorage) {
          setKeys(accessToken, accessTokenKey, tokenType, identityToken)
        }

        // Inject Axios interceptor
        if (!noHeaders) {
          authInterceptor = axios.interceptors.request.use(
            config => {
              if (
                !Object.prototype.hasOwnProperty.call(
                  config.headers,
                  'Authorization'
                )
              ) {
                if (
                  session.accessToken &&
                  shouldSendHeaders(config.url) &&
                  tokenType === 'Bearer'
                ) {
                  config.headers.Authorization = injectBearer(
                    session.userInfo.accessToken
                  )
                }
              } else if (!config.headers.Authorization) {
                delete config.headers.Authorization
              }
              return config
            },
            error => {
              return Promise.reject(error)
            }
          )
        }

        setSession(session)
        onLogin(null, session)
        setLoginCheck(true)

        if (redirect) {
          window.location.replace(redirect)
          storageClear(PRAXIS_REDIRECT_AFTERAUTH)
        }
      } else {
        onLogin(
          new Error(
            'Failed to validate tokens. They have either expired or were not parsed correctly.'
          ),
          null
        )
        clearAllStorage(accessTokenKey)
        setLoginCheck(true)
      }
    },
    [
      accessTokenKey,
      authorizationUrl,
      clientId,
      doesSSOCookieMatch,
      extraUserInfoFields,
      noHeaders,
      onLogin,
      shouldSendHeaders,
      SSOCookieName,
      tokenType,
    ]
  )

  // Initially false until login checks have gone through
  // used to block rendering
  const [loginCheck, setLoginCheck] = useState(false)

  // Check for cases where we are already logged in either:
  // - tokens are already present in localStorage
  // - tokens are in the address bar
  useEffect(() => {
    // don't try to log in if we already have it
    if (!loginCheck && !session) {
      // 0. Check if nonce is given and warn!
      if (nonce) {
        if (isDevEnv()) {
          console.warn(
            'Warning: `nonce` prop is now managed internally within `@praxis/component-auth`. Remove this prop from `AuthProvider`'
          )
        }
      }
      // 1. check for localStorage
      let accessToken = removeBearer(storageGet(accessTokenKey))
      let identityToken = storageGet(ID_TOKEN)
      let redirect = false
      let setStorage = false

      // 2. check for tokens in URL if not found in localStorage
      if (!accessToken && !popUp && window.location.hash.length) {
        const tokens = getTokensFromHash()
        accessToken = tokens.accessToken
        identityToken = tokens.identityToken

        //redirect to the original location if present
        window.history.replaceState(
          null,
          null,
          window.location.href.split('#')[0]
        )
        redirect = getRedirect()
        setStorage = true
      }

      if (accessToken) {
        // if tokens were found, log in as them if they are valid
        saveSession(accessToken, identityToken, setStorage, redirect)
      } else {
        // we are entering the app anew
        // --- or ---
        // in the case where we are logging in but have failed isFullPageAuth but
        // we still have a hash, we are probably in the popup redirect and should let login
        // handle grabbing the tokens instead. In that case we do not want to clear nonce
        // because we have yet to do any verification and we want to clear redirect
        // because login will be passing that through
        window.location.hash.length
          ? storageClear(PRAXIS_REDIRECT_AFTERAUTH)
          : clearAllStorage(accessTokenKey)
        // 3. allow login / logout
        setLoginCheck(true)
      }
    }
  }, [
    accessTokenKey,
    loginCheck,
    nonce,
    popUp,
    saveSession,
    session,
    setLoginCheck,
  ])

  // Remove axios interceptor when component unmounts
  useEffect(() => {
    return () => {
      axios.interceptors.request.eject(authInterceptor)
    }
  }, [])

  // logging in
  const login = useCallback(
    (args = {}) => {
      const { redirect } = args
      if (!session && loginCheck) {
        const formattedAuthUrl = new URL(authorizationUrl).toString()
        const config = {
          clientId,
          loginRedirect,
          responseType,
          scope,
          tokenType,
        }
        if (!popUp) {
          // save current route
          if (redirect) {
            storageSet(PRAXIS_REDIRECT_AFTERAUTH, new URL(redirect).toString())
          }
          // redirect to OAuth login page
          window.location.replace(createLoginUrl(formattedAuthUrl, config))
        } else {
          // open up a popup with promise
          const popupWindow = createPopupWindow(
            createLoginUrl(formattedAuthUrl, config),
            popUpOptions
          )
          if (!popupWindow && isDevEnv()) {
            console.error(
              'ERROR: window.open() did not return a handle. The login flow may not complete. See https://praxis.target.com/authentication/#ie11-quirk for more information.'
            )
          }
          // logic for polling the popup window for login information
          const polling = setInterval(async () => {
            // check for premature closure of popup
            if (
              !popupWindow ||
              popupWindow.closed ||
              popupWindow.closed === undefined
            ) {
              clearInterval(polling)
              onLogin(
                new Error('The auth window was closed before authenticating.'),
                null
              )
            }
            try {
              // if we were redirected back to our app we can assume tokens are present
              if (popupWindow.location.href.split('#')[0] === loginRedirect) {
                clearInterval(polling)

                if (popupWindow.location.hash.length) {
                  const { accessToken, identityToken } = getTokensFromHash(
                    popupWindow
                  )

                  await saveSession(accessToken, identityToken, true, redirect)
                } else {
                  onLogin(
                    new Error(
                      'OAuth redirect has occurred but no query or hash parameters were found.'
                    ),
                    null
                  )
                }
                // cleanup
                popupWindow.close()
              }
            } catch (error) {
              // try/catch IE workaround since IE will throw an error here. We just swallow the error
            }
          }, 500)
        }
      }
    },
    [
      authorizationUrl,
      clientId,
      loginCheck,
      loginRedirect,
      onLogin,
      popUp,
      popUpOptions,
      responseType,
      saveSession,
      scope,
      session,
      tokenType,
    ]
  )

  // logging out
  const logout = useCallback(() => {
    // don't allow logout until token checks have finished
    clearAllStorage(accessTokenKey)
    // redirect to OAuth logout page
    const formattedLogoutUrl = createLogoutUrl(new URL(logoutUrl).toString(), {
      logoutRedirect,
    })
    if (!popUp) {
      window.location.replace(formattedLogoutUrl)
    } else {
      createPopupWindow(formattedLogoutUrl, popUpOptions)
      setSession(null)
      onLogout()
    }
  }, [accessTokenKey, logoutRedirect, logoutUrl, onLogout, popUp, popUpOptions])

  // no user info or login checks yet, don't try to render anything
  return session === null && !loginCheck ? (
    !hideLoadingIndicator ? (
      <LoadingC />
    ) : null
  ) : typeof children === 'function' ? ( // check for render prop or just children pass-through
    children({
      session,
      isAuthenticated: () =>
        (session ? session.isAuthenticated() : false) &&
        (SSOCookieName ? doesSSOCookieMatch(SSOCookieName, session) : true),
      isAuthorized: groups => (session ? session.isAuthorized(groups) : false),
      login: login,
      logout: logout,
      isFullPageAuth: () => !popUp,
    })
  ) : (
    children
  )
}

Authentication.propTypes = {
  children: T.oneOfType([T.func, T.node]).isRequired,

  accessTokenKey: T.string,
  doesSSOCookieMatch: T.func,
  extraUserInfoFields: T.arrayOf(T.string),
  popUp: T.bool,
  loadingIndicator: T.func,
  onLogin: T.func,
  onLogout: T.func,
  noHeaders: T.bool,
  shouldSendHeaders: T.func,
  hideLoadingIndicator: T.bool,
  SSOCookieName: T.string,

  // config
  authorizationUrl: T.string,
  clientId: T.string,
  loginRedirect: T.string,
  logoutRedirect: T.string,
  logoutUrl: T.string,
  popUpOptions: T.shape({
    width: T.number,
    height: T.number,
  }),
  responseType: T.string,
  scope: T.arrayOf(T.string),
  storageType: T.string,
}

Authentication.defaultProps = {
  accessTokenKey: ACCESS_TOKEN,
  doesSSOCookieMatch: matchesCookie,
  extraUserInfoFields: [],
  popUp: false,
  loadingIndicator: Loading,
  onLogin: () => {},
  onLogout: () => {},
  noHeaders: false,
  shouldSendHeaders: shouldSendHeaders,
  hideLoadingIndicator: false,

  // config
  authorizationUrl: 'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
  loginRedirect: `${window.location.origin}`,
  logoutUrl:
    'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
  popUpOptions: { width: 482, height: 680 },
  responseType: 'token id_token',
  scope: ['openid profile'],
  storageType: 'localStorage',
  tokenType: 'Bearer',
}

export default React.memo(Authentication)
