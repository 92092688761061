import React from 'react'
import useAuth from './useAuth'
import Route from './ProtectedRouteBase'

const ProtectedRoute = props => {
  const auth = useAuth()
  const passThroughProps = auth
    ? { ...props, session: auth.session, onUnauthenticated: auth.login }
    : props
  return <Route {...passThroughProps} />
}

export default ProtectedRoute
